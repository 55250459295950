import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../components/Firebase/Firebase';
import GlobalStyles from '../styles/Globalstyles';
import Typography from '../styles/Typography';
import Logo from '../components/Logo';

const LoginStyles = styled.div`
  display: block;
  width: 100%;
  padding: 100px 0;
  text-align: center;
  h1 {
    margin-top: 100px;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding: 40px 0;
    h1 {
      margin-top: 40px;
      font-size: 22px;
      line-height: 30px;
    }
  }
`;
const LoginFormStyles = styled.div`
  width: 500px;
  max-width: calc(100% - 40px);
  margin: 40px auto 0;
  padding: 40px;
  background: var(--grey);
  border-radius: 5px;
  .inputBox {
    margin-bottom: 20px;
    :last-child {
      margin-bottom: 0;
    }
    label {
      display: block;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
    input {
      width: 100%;
      height: 60px;
      padding: 0 20px;
      background: var(--white);
      border: 0;
      border-radius: 5px;
      font-size: 16px;
      color: var(--black);
      font-weight: 500;
    }
    button {
      display: block;
      width: 100%;
      padding: 20px;
      background: var(--black);
      border-radius: 5px;
      font-size: 16px;
      line-height: 20px;
      color: var(--white) !important;
      font-weight: 500;
      transition: background 0.2s ease-in-out;
      :hover {
        background: var(--green);
      }
    }
  }
  .errorMsg {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    color: #dc143c !important;
    text-align: left;
  }
  @media (max-width: 767px) {
    margin-top: 20px;
    .inputBox {
      label {
        font-size: 14px;
        line-height: 18px;
      }
      input {
        padding: 0 10px;
        font-size: 14px;
      }
      button {
        font-size: 14px;
      }
    }
    .errorMsg {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media (max-width: 549px) {
    padding: 20px;
  }
`;

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(`/`);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <GlobalStyles />
      <Typography />
      <LoginStyles>
        <Logo />
        <h1>Login</h1>
        <LoginFormStyles>
          {error && <p className="errorMsg">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="inputBox">
              <label>Email</label>
              <input
                id="emailInput"
                type="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="inputBox">
              <label>Password</label>
              <input
                type="password"
                name="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="inputBox">
              <button type="submit">submit</button>
            </div>
          </form>
        </LoginFormStyles>
      </LoginStyles>
    </>
  );
}
